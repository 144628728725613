export default [

  {
    title: 'Job',
    icon: 'FileTextIcon',
    route: 'apps-jobs-list',
  }, 
  
  {
    title: 'Schedule',
    icon: 'MapIcon',
    route: 'apps-schedules-edit',
  }, 
  {
    header: 'Setup & Configuration',
  },
  {
    title: 'User',
    icon: 'UserIcon',
    route: 'apps-users-list',
  },
  {
    title: 'Material',
    icon: 'PackageIcon',
    route: 'apps-materials-list',
  },
  
  {
    title: 'Tool',
    icon: 'ToolIcon',
    route: 'apps-tools-list',
  },
  {
    title: 'Vehicle',
    icon: 'TruckIcon',
    route: 'apps-vehicles-list',
  },
]
